"use client";

import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import NextTopLoader from "nextjs-toploader";
import React from "react";
import Script from "next/script";
import { META_PIXEL_ID } from "@/utils/landingPage/fbPixel";
import { checkCookiesParamsLandingPage } from "@/constants/cookie";
import { TagDataLayer } from "@/utils/landingPage/logEvent";

export default function RootLayout({
  children,
  params: { locale },
}: {
  children: React.ReactNode;
  params: { locale: string };
}) {
  checkCookiesParamsLandingPage();
  return (
    <html lang={locale}>
      <GoogleTagManager gtmId="GTM-NVP6QRWV" dataLayerName={TagDataLayer} />
      <GoogleAnalytics gaId="G-7NL3S1KDL0" dataLayerName="analyticsDataLayer" />
      <body>
        <NextTopLoader />
        {children}
        <Script id="fb-pixel" strategy="afterInteractive">
          {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${META_PIXEL_ID}');
              fbq('track', 'PageView');
            `}
        </Script>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${META_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </body>
    </html>
  );
}
