import Cookies from "universal-cookie";
import { format } from "date-fns";

export const cookies = new Cookies();

export const cookieOptions = { path: "/", domain: "adahub.vn" };

export const setCookie = (key: string, value: string | undefined) => {
  cookies.set(key, value, cookieOptions);
  cookies.set(key, value, { ...cookieOptions, domain: "localhost" });
  cookies.set(key, value, { path: "/" });
};

export const removeCookie = (key: string) => {
  cookies.remove(key, cookieOptions);
  cookies.remove(key);
};

export const checkCookiesParamsLandingPage = () => {
  if (typeof window === "undefined") {
    return;
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params["date"]) {
    setCookie("date", params["date"]);
  }
  if (params["channel"]) {
    setCookie("channel", params["channel"]);
  }
  if (params["marketer"]) {
    setCookie("marketer", params["marketer"]);
  }
  if (params["label"]) {
    setCookie("label", params["label"]);
  }
  if (params["content"]) {
    setCookie("content", params["content"]);
  }
  if (params["contact"]) {
    setCookie("contact", params["contact"]);
  }
  if (params["referrer"]) {
    setCookie("referrer", params["referrer"]);
  }
  if (params["gclid"]) {
    setCookie("gclid", params["gclid"]);
  }
  if (params["fbclid"]) {
    setCookie("fbclid", params["fbclid"]);
  }
};

export const cookieParamsLandingPage = () => {
  return {
    date: cookies.get("date")
      ? cookies.get("date")
      : format(new Date(), "LLLdd")?.toUpperCase(),
    referrer: cookies.get("referrer"),
    gclid: cookies.get("gclid"),
    fbclid: cookies.get("fbclid"),
    channel: cookies.get("channel") ? cookies.get("channel") : "NULL",
    marketer: cookies.get("marketer") ? cookies.get("marketer") : undefined,
    label: cookies.get("label") ? cookies.get("label") : "NULL",
    content: cookies.get("content") ? cookies.get("content") : "NULL",
    contact: cookies.get("contact") ? cookies.get("contact") : "NULL",
  };
};
